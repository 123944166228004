import SearchableBassinBlock from "../components/blocks/SearchableBassinBlock";
import SearchablePoolBlock   from "../components/blocks/SearchablePoolBlock";

const ZwembadenBassinsOverview = () => {

    return (<>
        <SearchablePoolBlock/>
    </>)
}

export default ZwembadenBassinsOverview