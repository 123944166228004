
//<editor-fold desc="Retrieval">
import {
    AuthToken,
    baseUrl,
    PoolUrl
} from "./constants";

export function getPoolRecord(Identifier: any)
{
    const requestOptions = {
        method: 'GET',
        headers: {
            Authorization: 'Bearer ' + AuthToken
        }
    };

    return fetch(baseUrl + PoolUrl +Identifier, requestOptions).then()

}
//</editor-fold>
//<editor-fold desc="Update">

export function submitObject(details: any, AggregateId : string, Command: string)
{
    const requestOptions = {
        method: 'POST',
        headers: {
            Authorization: 'Bearer ' + AuthToken
        },
        body: JSON.stringify(details)
    };
    return fetch(baseUrl + PoolUrl + AggregateId + Command, requestOptions)
        .then(response => response)
}

//</editor-fold>
