import {
    Button,
    Typography
}                      from "@mui/material";
import {
    useEffect,
    useRef
}                      from "react";
import {
    RegisterPoolCommand,
    Pool,
    PoolDetail,
    UpdatePoolCommand
}                      from "../constants";
import {useZINContext} from "../context/ZinContextProvider";
import {submitObject}  from "../util";

const PoolForm = () =>
{
    const {
        poolDetails,
        formData,
        setModalVisible,
        setAlertText, setAlertVisible,
        setRefreshResource
    } = useZINContext()

    const FormRef = useRef<any>()

    useEffect(() =>
    {
        if (FormRef.current !== undefined && poolDetails !== undefined)
        {
            FormRef.current.data = poolDetails.pool
            if(formData !== undefined) {
                FormRef.current.data = {...formData}
            }
        }
    }, []);

    async function HandleSubmit(event: any)
    {

        //In case of a status that isnt 200, we most likely have received an error message.
        //So we display this as alert in the Modal
        if (event.status === 200)
        {
            //If everything went okay we can close it
            setModalVisible(false)
            setAlertVisible(false)
        }
        else
        {
            let response = await event.json()
            setAlertVisible(true)
            setAlertText(response.messages.reduceRight((acum: any, current: any) => acum.concat(current)))
        }

    }

    function onClickSubmit()
    {
        let Data: any = {}
        // @ts-ignore
        FormRef.current.getData().then((event: any) => Data = event)
        // @ts-ignore
        FormRef.current.submitForm().then(() =>
            {
                if (poolDetails === undefined)
                {
                    submitObject(Data, Data.locationDossierNumber, RegisterPoolCommand).then(async (event: any) =>
                    {
                        HandleSubmit(event)
                    })
                    setRefreshResource(Pool)

                }
                else
                {
                    submitObject(Data, Data.locationDossierNumber, UpdatePoolCommand).then(async (event: any) =>
                    {
                        HandleSubmit(event)
                    })
                    setRefreshResource(PoolDetail)

                }
            }
        )

    }

    return (<>

        <Typography id="modal-modal-title" variant="h6" component="h2">
            {poolDetails === undefined
             ? "Zwembadinrichting toevoegen"
             : "Zwembadinrichting bewerken"}
        </Typography>

        {poolDetails === undefined
         ?            /*// @ts-ignore*/
         <ig-form lang="nl-NL" ref={FormRef} form-id="RegisterPool"/>
         :            /*// @ts-ignore*/
         <ig-form lang="nl-NL" ref={FormRef} form-id="UpdatePool"/>
        }
        <Button variant="contained" onClick={onClickSubmit}>Submit</Button>
    </>)
}
export default PoolForm