import {
    applyPolyfills,
    defineCustomElements as defineIgWebComponents
}                             from "informationgrid-webcomponents/dist/loader/index";
import "./resources/style.scss"
import React                from 'react';
import './App.css';
import {ZINContextProvider} from "./context/ZinContextProvider";
import MainPage               from "./pages/MainPage";

applyPolyfills().then(() => defineIgWebComponents(window));

function App()
{
    return (
        <div className="App">
            <ZINContextProvider>
                <MainPage/>
            </ZINContextProvider>
        </div>
    );
}

export default App;
