import {
    Button,
    Grid,
    Typography
}                      from "@mui/material";
import {
    useEffect,
    useRef,
    useState
} from "react";
import {
    AddAdres,
    Adres,
    AdresDetail,
    Aerosol,
    AerosolDetail,
    IGStyle,
    UpdateAdres
} from "../../constants";
import {useZINContext} from "../../context/ZinContextProvider";

const AdresBlock = () =>
{
    const {
        poolDetails,
        setActivePage,
        setPoolDetails,
        poolDSLFilter,
        setPoolDSLFilter,
        setFormData,
        setModalVisible,
        setModalAction,
        refreshResource, setRefreshResource
    } = useZINContext()

    const RMRef = useRef<any>()
    const RDRef = useRef<any>()
    const [detailStatus, setDetailStatus] = useState<any>(false)
    const [detailRecord, setDetailRecord] = useState<any>("")

    useEffect(() =>
    {
        RMRef.current?.addEventListener('recordSelected', (event: any) =>
        {
            setFormData(event.detail.record)
            setDetailStatus(true)
            setDetailRecord(event.detail.recordId)
        })
    }, [])

    useEffect(() =>
    {
        switch (refreshResource)
        {
            case Adres:
                setTimeout(() => RMRef.current?.refresh(), 1000)
                break
            case AdresDetail:
            setTimeout(() => RDRef.current?.refresh(), 1000)
            break
        }
        setRefreshResource("")
    }, [refreshResource]);

    function ResetDetailStatus()
    {
        setRefreshResource(Adres)
        setDetailStatus(false)
    }

    function AdresToevoegen()
    {
        setFormData(undefined)

        setModalVisible(true)
        setModalAction(AddAdres)
    }

    function AdresBewerken()
    {
        setFormData(RDRef.current?.data)

        setModalVisible(true)
        setModalAction(UpdateAdres)
    }

    return (<>
        <Grid container justifyContent="center" direction={"column"}>
            <Grid container className={"rm-container"} direction={"row"}>
                <Grid container>
                    <Typography variant="h4" marginRight={"auto"}>
                        Adres
                    </Typography>
                </Grid>

                <Grid item xs={8} container alignItems={"stretch"} className={"containerspacing"}>

                    <div style={{
                        display: detailStatus
                                 ? "contents"
                                 : "none"
                    }}>
                        <Button onClick={ResetDetailStatus}>Back</Button>
                        {/*// @ts-ignore*/}
                        <ig-resource-detail lang="nl-NL" ref={RDRef} style={IGStyle}  class={"ig-detail-remove-2rows"} resource-detail-id="AddressRD" resource-id={detailRecord}/>
                    </div>
                    <div style={{
                        display: detailStatus
                                 ? "none"
                                 : "contents"
                    }}>                     {/*// @ts-ignore*/}
                        <ig-resource-master lang="nl-NL" ref={RMRef} style={IGStyle} class={"ig-columns ig-hide-pager"} resource-master-id="AddressRM" dsl-filter={poolDSLFilter}/>
                    </div>

                </Grid>

                <Grid container item className={"button-container"} xs={3} marginLeft={1} justifyContent={"flex-start"}>

                    <Grid item xs={4}>
                        <Grid container direction="column" item>
                            <Button variant={"contained"} onClick={AdresToevoegen}>Adres Toevoegen</Button>
                            <Button variant={"contained"} disabled={!detailStatus} onClick={AdresBewerken}>Adres Bewerken</Button>
                        </Grid>
                    </Grid>

                </Grid>

            </Grid>
        </Grid>
    </>)
}

export default AdresBlock