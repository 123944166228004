import AdresBlock             from "../components/blocks/AdresBlock";
import ContactenBlock         from "../components/blocks/ContactenBlock";
import ZwembadInrichtingBlock from "../components/blocks/ZwembadInrichtingBlock";

const ZwembadOverview = () => {

    return (<>
        <ZwembadInrichtingBlock/>
        <AdresBlock/>
        <ContactenBlock/>
    </>)
}
export default ZwembadOverview