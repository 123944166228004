import {
    Button,
    Grid,
    Typography
}                      from "@mui/material";
import {
    useEffect,
    useRef
}                      from "react";
import {
    DeactivatePoolCommand,
    IGStyle,
    Pool,
    PoolDetail,
    UpdatePool
}                      from "../../constants";
import {useZINContext} from "../../context/ZinContextProvider";
import {submitObject}  from "../../util";

const ZwembadInrichtingBlock = () =>
{
    const {
        poolDetails,
        refreshResource,
        setRefreshResource,
        setFormData,
        setModalVisible,
        setModalAction,
        setActivePage,
    } = useZINContext()

    const RDRef = useRef<any>()

    useEffect(() =>
    {
        switch (refreshResource)
        {
            case PoolDetail:
                setTimeout(() => RDRef.current?.refresh(), 1000)
                break
        }
        setRefreshResource("")
    }, [refreshResource]);

    function ZwembadinrichtingBewerken()
    {
        setFormData(RDRef.current?.data)

        setModalVisible(true)
        setModalAction(UpdatePool)
    }

    function ZwembadinrichtingDeactiveren()
    {
        submitObject({locationDossierNumber: poolDetails?.id}, poolDetails?.id, DeactivatePoolCommand)
        setActivePage(0)
        setRefreshResource(Pool)
    }

    return (<>
        <Grid container justifyContent="center" direction={"column"}>
            <Grid container className={"rm-container"} direction={"row"}>
                <Grid container>
                    <Typography variant="h4" marginRight={"auto"}>
                        Zwembadinrichting
                    </Typography>
                </Grid>

                <Grid item xs={8} container alignItems={"stretch"} className={"containerspacing"}>
                    {/*// @ts-ignore*/}
                    <ig-resource-detail lang="nl-NL" ref={RDRef} style={IGStyle} resource-detail-id="PoolRD" resource-id={poolDetails?.id}/>
                </Grid>

                <Grid container item className={"button-container"} xs={3} marginLeft={1} justifyContent={"flex-start"}>

                    <Grid item xs={4}>
                        <Grid container direction="column" item>
                            <Button variant={"contained"} onClick={ZwembadinrichtingBewerken}>Badinrichting Bewerken</Button>
                            <Button variant={"contained"} onClick={ZwembadinrichtingDeactiveren}>Badinrichting Deactiveren</Button>
                        </Grid>
                    </Grid>

                </Grid>

            </Grid>
        </Grid>
    </>)
}

export default ZwembadInrichtingBlock