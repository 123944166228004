import {
    Alert,
    Box,
    Modal
}                      from "@mui/material";
import React           from "react";
import {
    AddAdres,
    AddAerosol,
    AddBassin,
    AddContact,
    AddInstallatie,
    AddPool,
    UpdateAdres,
    UpdateAerosol,
    UpdateBassin,
    UpdateContact,
    UpdateInstallatie,
    UpdatePool
} from "../constants";
import {useZINContext} from "../context/ZinContextProvider";
import AdresForm       from "../forms/AdresForm";
import AerosolForm from "../forms/AerosolForm";
import BassinForm      from "../forms/BassinForm";
import ContactForm     from "../forms/ContactForm";
import InstallatieForm from "../forms/InstallatieForm";
import PoolForm        from "../forms/PoolForm";

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    maxHeight: "80vh",
    overflowY: "auto",
    overflowX: "auto",
    transform: 'translate(-50%, -50%)',
    maxWidth: 800,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

const UpsertObjectModal = () =>
{
    const {
        setModalVisible, modalVisible,
        modalAction,
        alertText, setAlertText,
        alertVisible, setAlertVisible
    } = useZINContext()

    const handleClose = () =>
    {
        setModalVisible(false);
        setAlertVisible(false)
    }

    function CurrentForm()
    {
        switch (modalAction)
        {
            case AddPool:
            case UpdatePool:
                return <PoolForm/>
            case AddAdres:
            case UpdateAdres:
                return <AdresForm/>
            case AddBassin:
            case UpdateBassin:
                return <BassinForm/>
            case AddContact:
            case UpdateContact:
                return <ContactForm/>
            case AddInstallatie:
            case UpdateInstallatie:
                return <InstallatieForm/>
            case AddAerosol:
            case UpdateAerosol:
                return <AerosolForm/>
            default:
                return "Form is missing from Modal"

        }
    }

    return (<>
        <Modal
            open={modalVisible}
            onClose={handleClose}
        >
            <Box sx={style}>
                <>
                    <Alert style={{
                        display: alertVisible
                                 ? "flex"
                                 : "none"
                    }} severity="error" color={"warning"}>{alertText}</Alert>

                    {CurrentForm()}
                </>
            </Box>
        </Modal>
    </>)
}
export default UpsertObjectModal