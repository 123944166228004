import {
    Alert,
    Button,
    Grid
}                         from "@mui/material";
import React, {useEffect} from "react";
import MeasurementsBlock  from "../components/blocks/MeasurementsBlock";
import {useZINContext}    from "../context/ZinContextProvider";
import UpsertObjectModal        from "../modals/UpsertObjectModal";
import AerosolOverview          from "./AerosolOverview";
import BassinOverview           from "./BassinOverview";
import InstallatiesOverview     from "./InstallatiesOverview";
import ZwembadenBassinsOverview from "./ZwembadenBassinsOverview";
import ZwembadOverview          from "./ZwembadOverview";

const MainPage = () =>
{

    const {activePage, setActivePage,
        subPage, setSubPage,
        alertVisible,alertText,
        setAlertVisible,
    } = useZINContext()

    useEffect(() =>
    {
        setAlertVisible(false)
    }, [subPage,activePage]);

    function onClickHome()
    {
        setActivePage(0)
    }

    function onClickBack()
    {
        //Decrement it by one to go back a page, disspears when activepage is 0
        if (activePage > 0)
        {
            setActivePage((activePage: number) => activePage - 1)
        }
    }

    const TopButtons = <Grid container justifyContent="center">
        <Grid item>
            <Grid item>
                <Button variant={"contained"} className={"default-button"} onClick={onClickHome}>Home</Button>
                {activePage !== 0 && <Button variant={"contained"} className={"default-button"} onClick={onClickBack}>Terug</Button>}
                <Alert style={{
                    display: alertVisible
                             ? "flex"
                             : "none",
                }} severity="error" color={"warning"}>{alertText}</Alert>

            </Grid>
        </Grid>
    </Grid>

    const SelectionButtons = <Grid container className={"rm-container"} justifyContent={"flex-start"} flexDirection={"row"}>
        <Button onClick={() => setSubPage(0)} variant={subPage === 0
                                                       ? "outlined"
                                                       : "text"}>Zwembadinrichting</Button>
        <Button onClick={() => setSubPage(1)} variant={subPage === 1
                                                       ? "outlined"
                                                       : "text"}>Bassins</Button>
        <Button onClick={() => setSubPage(2)} variant={subPage === 2
                                                       ? "outlined"
                                                       : "text"}>Installaties</Button>
        <Button onClick={() => setSubPage(3)} variant={subPage === 3
                                                       ? "outlined"
                                                       : "text"}>Aerosol Elementen</Button>
        <div style={{paddingTop:4}}>|</div>
        <Button onClick={() => setSubPage(4)} variant={subPage === 4
                                                       ? "outlined"
                                                       : "text"}>Meetgegevens</Button>
    </Grid>

    return (<>
        <p>ZIN</p>
        <Grid container direction={"column"}>
            {TopButtons}

            <Grid className={"rm-container"}>

                {activePage === 0 && <ZwembadenBassinsOverview/>}
                {activePage === 1 && SelectionButtons}
                {activePage === 1 && subPage === 0 && <ZwembadOverview/>}
                {activePage === 1 && subPage === 1 && <BassinOverview/>}
                {activePage === 1 && subPage === 2 && <InstallatiesOverview/>}
                {activePage === 1 && subPage === 3 && <AerosolOverview/>}
                {activePage === 1 && subPage === 4 && <MeasurementsBlock/>}

            </Grid>
            <UpsertObjectModal/>

        </Grid>

    </>)
}
export default MainPage