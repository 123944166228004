import {
    Button,
    Grid,
    Typography
}                      from "@mui/material";
import {
    useEffect,
    useRef
}                      from "react";
import {
    AddPool,
    IGStyle,
    Pool
}                      from "../../constants";
import {useZINContext} from "../../context/ZinContextProvider";
import {getPoolRecord} from "../../util";

const SearchablePoolBlock = () =>
{
    const {
        activePage, setActivePage,
        setPoolDetails, setSubPage,
        setPoolDSLFilter,
        setFormData,
        setModalVisible,
        setModalAction,
        refreshResource, setRefreshResource,
    } = useZINContext()

    const RMRef = useRef<any>()

    useEffect(() =>
    {
        RMRef.current?.addEventListener('recordSelected', (event: any) =>
        {
            getPoolRecord(event.detail.recordId).then(async (data: any) =>
                {
                    const Data = await data.json()
                    setActivePage(1)
                    setSubPage(0)
                    setPoolDetails(Data)
                    setPoolDSLFilter(`aggregateInstanceIdentifier =="` + event.detail.recordId + "\"")
                }
            )
        })
    }, [])

    useEffect(() =>
    {
        switch (refreshResource)
        {
            case Pool:
                setTimeout(() => RMRef.current?.refresh(), 1000)
                break
        }
        setRefreshResource("")
    }, [refreshResource]);

    function AddBadinrichting()
    {
        setFormData(undefined)
        setPoolDetails(undefined)

        setModalVisible(true)
        setModalAction(AddPool)
    }

    return (<>
        <Grid container justifyContent="center" direction={"column"}>
            <Grid container className={"rm-container"} direction={"row"} display={activePage === 0
                                                                                  ? "flex"
                                                                                  : "none"}>
                <Grid container>
                    <Typography variant="h4" marginRight={"auto"}>
                        Zwembadinrichtingen
                    </Typography>
                </Grid>

                <Grid item xs={8} container alignItems={"stretch"} className={"containerspacing"}>
                    {/*// @ts-ignore*/}
                    <ig-resource-master lang="nl-NL" style={IGStyle} ref={RMRef} class={"ig-master-shading"} searchable-resource-master-id="SearchablePoolRM"/>
                </Grid>

                <Grid container item className={"button-container"} xs={3} marginLeft={1} justifyContent={"flex-start"}>

                    <Grid item xs={4}>
                        <Grid container direction="column" item>
                            <Button variant={"contained"} onClick={AddBadinrichting}>Badinrichting Toevoegen</Button>
                        </Grid>
                    </Grid>

                </Grid>

            </Grid>
        </Grid>
    </>)
}

export default SearchablePoolBlock