import {
    createContext,
    useContext,
    useState,
} from "react";

export const ZINContext = createContext<any>(undefined);
export const useZINContext = () => useContext(ZINContext);
export const ZINContextProvider = ({children}: any) =>
{
    const [activePage, setActivePage] = useState<number>(0)
    const [subPage, setSubPage] = useState<number>(0)


    const [poolDetails, setPoolDetails] =  useState<any>()
    const [poolDSLFilter, setPoolDSLFilter] = useState<any>()

    const [formData, setFormData] = useState<any>()

    const [modalVisible, setModalVisible] = useState<boolean>(false)
    const [modalAction, setModalAction] = useState<boolean>(false)
    const [alertText, setAlertText] = useState<string>("")
    const [alertVisible, setAlertVisible] = useState<boolean>(false)

    const [refreshResource, setRefreshResource] =useState<string>("")

    return (
        <ZINContext.Provider
            value={{
                activePage, setActivePage,
                subPage, setSubPage,

                poolDetails, setPoolDetails,
                poolDSLFilter, setPoolDSLFilter,

                formData, setFormData,

                modalVisible, setModalVisible,
                modalAction, setModalAction,
                alertText, setAlertText,
                alertVisible, setAlertVisible,

                refreshResource, setRefreshResource
            }}>
            {children}

        </ZINContext.Provider>
    );

}